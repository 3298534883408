import React, { useEffect, useState, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import HeaderJobs from "components/Headers/HeaderJobs";
import FooterJobs from "components/Footers/FooterJobs";
import { useParams, Link } from "react-router-dom";
import { MapIcon, BriefcaseIcon, CurrencyDollarIcon, PlusIcon } from "@heroicons/react/24/outline";
import Modal from "components/Modals/Modal"
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik"
import * as Yup from "yup"
import Alert from "components/Alerts/Alert"
import Loader from "components/Loaders/Loader"
import {   EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


import PostulacionStorePublic from "views/jobs/services/PostulacionStorePublic";
import ShowVacante from "views/jobs/services/ShowVacante";
//import FileUploadExample from "views/jobs/services/FileUploadExample";
//import MyForm from "views/jobs/services/MyForm";


import AlertHeadless from "components/Alerts/AlertHeadless";



export default function ViewJob() {
  const params = useParams();
  const [vacanteData, setVacanteData] = useState([]);
  const [jobPosting, setJobPosting] = useState();
  const [showModal, setShowModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showAlertErrors, setShowAlertErrors] = useState(false)
  const [showAlertHeadless, setShowAlertHeadless] = useState(false)
  const [errors, setErrors] = useState({})



//const [curriculumField, curriculumMeta] = useField("curriculum");

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
 

  const formRef = useRef(null);

  useEffect(() => {
    async function getVacante() {
      try {
        const vacante = await ShowVacante(params.vacanteId);
        vacante.success ? setVacanteData(vacante.data) : console.log(vacante.message);
        //const dom = htmlToDOM(vacante.data.description);
        //setReactComponents( domToReact(dom)) ;
        if (vacante.data.description) {
          const contentState = ContentState.createFromBlockArray(convertFromHTML(vacante.data.description));
          const editorContentState = EditorState.createWithContent(contentState);
          setEditorState(editorContentState);
        }

   

        if (vacante.success) {
          const datos = vacante.data;

          let googleJson =
          {
            "@context": "https://schema.org/",
            "@type": "JobPosting",
            "title": `${datos.title}`,
            "description": `${datos.description}`,
            "identifier": {
              "@type": "PropertyValue",
              "name": "Talenty",
              "value": "5440064"
            },
            "datePosted": `${datos.datePosted}`,
            "validThrough": `${datos.validThrough}`,
            "employmentType": `${datos.employmentType}`,
            "hiringOrganization": {
              "@type": "Organization",
              "name": `${datos.hiringOrganization}`,
              "sameAs": "https://talenty.com.mx",
              "logo": "https://talenty-api.fly.dev/imgs/talentylogo.png"
            },
            "jobLocation": {
              "@type": "Place",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": `${datos.streetAddress}`,
                "addressLocality": `${datos.addressLocality}`,
                "addressRegion": `${datos.addressRegion}`,
                "postalCode": `${datos.postalCode}`,
                "addressCountry": `${datos.addressCountry}`
              }
            },
            "baseSalary": {
              "@type": "MonetaryAmount",
              "currency": `${datos.baseSalaryCurrency}`,
              "value": {
                "@type": "QuantitativeValue",
                "value": parseFloat(datos.baseSalaryValue),
                "unitText": `${datos.baseSalaryUnitText}`
              }
            }
          };
          setJobPosting(JSON.stringify(googleJson));
          setLoading(false);
        }

      } catch (errors) {
        console.log(errors)
      }
    }
    getVacante();

  }, [params.vacanteId]);


  function onCancelModal() {
    setShowModal(false);
    setProcessing(false);
  }


  function onAceptModal() {
    // Validar si el formulario está presente antes de hacer el submit
    if (formRef.current) {
      formRef.current.handleSubmit();
      formRef.current.validateForm().then(errors => {
        if (Object.keys(errors).length === 0) {
          // No hay errores de validación, puedes enviar el formulario

          setProcessing(true);
        } else {
          // Hay errores de validación, puedes manejarlos aquí
          setProcessing(false);
        }
      });
    }
  }


  function onCancelAlert() {
    setShowAlertHeadless(false);
  }




  return (
    <>
      

      {vacanteData.title && (
        <HelmetProvider>
          <Helmet>
            <title>Empleo de {vacanteData.title} en {vacanteData.addressLocality} </title>
            <meta name="description" content={vacanteData.title} />
            <script type="application/ld+json">{jobPosting}</script>
            <link rel="canonical" href={"https://talenty.com.mx/empleo/" + vacanteData.id} />
          </Helmet>
        </HelmetProvider>
      )}

      {/* <HeaderJobs title={loading ? <Loader color= "white" text="cargando..." /> : vacanteData.title} description= {loading ? <Loader color= "white" text="cargando..." /> : "$"+vacanteData.baseSalaryValue + " | " + vacanteData.addressLocality} />
 */}

  {loading ? <Loader  text="cargando..." /> :   <>
      <HeaderJobs title={ vacanteData.title} description= { "$"+vacanteData.baseSalaryValue + " | " + vacanteData.addressLocality} />
      </>}
      
      
      <div className="flex  justify-center h-auto">
    

        <div className="bg-white rounded-lg shadow-lg p-8  lg:w-4/5 sm:w-3/5 mx-2 ">
          
          <div className="flex flex-col  sm:flex-row">
            <div className="sm:w-1/5 mb-4  sm:mb-0 sm:mr-8">

              <button className="bg-blue-700 text-white py-2 px-10 rounded-lg mb-4"
                id="aplicarahorabutton"
                onClick={() => setShowModal(true)}
              >Aplicar ahora</button>
              <br />
              {loading ? <Loader text="cargando..." /> :  <p className="text-sm flex items-center font-bold"><BriefcaseIcon className="w-5 h-5  text-gray-400 mr-1" /> {vacanteData.hiringOrganization}</p>}
              <br />
              <p className="text-sm flex items-center font-bold"><MapIcon className="w-5 h-5 mr-1 text-gray-400 mr-1" /> {vacanteData.addressLocality}</p>
              <br />
              <p className="text-sm flex items-center font-bold"><CurrencyDollarIcon className="w-5 h-5 mr-1 text-gray-400 mr-1" />{vacanteData.baseSalaryValue} {vacanteData.baseSalaryCurrency}  </p>
              <br /> <br />
              <Link to="/empleos/">
                <button className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10 block lg: outline-none focus:outline-none"
                  type="button" id="masempleosbutton"><PlusIcon className="w-4 h-4 text-indigo-700 mr-1" /> Más empleos </button>

              </Link>

            </div>

            <div className="w-full">
              <p className="text-xl font-bold mb-4">Descripción de la vacante</p>
              {loading ? <Loader /> :( <> 

           {/* Presentamos contenido de la descripción de la vacante con html interpretado */}
               <Editor
                    editorState={editorState}
                    toolbarHidden={true}
                />
                 
                 
                 </> )}

                {/*  <MyForm/> */}


            </div>

          </div>

        </div>
      

      </div>


      <AlertHeadless

        showAlert={showAlertHeadless}
        titleAlert="Postulación exitosa"
        cancelAlert={onCancelAlert}
        setShowAlert={setShowAlertHeadless}
      >
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Te has postulado correctamente. En breve nos pondremos en contacto.
          </p>
          <br></br>
          <p className="text-sm text-blue-600">
            ¡Éxito!
          </p>
        </div>

      </AlertHeadless>


      <Modal title="Postularse" show={showModal} setShow={setShowModal} accept={onAceptModal} cancel={onCancelModal} processing={processing}>
        <div className="relative p-1 flex-auto w-80 h-64 overflow-y-auto">
          <Alert
            type={"warning"}
            show={showAlertErrors}
            onClose={() => setShowAlertErrors(false)}
          >
            {Object.keys(errors).length > 0 && (
              <div className="mb-4">
                <div className="font-medium text-black">
                  No se pudo enviar tu postulación.
                </div>
                <ul className="mt-3 list-disc list-inside text-sm text-black">
                  {Object.keys(errors).map(function (key, index) {
                    return <li key={index}>{errors[key]}</li>
                  })}
                </ul>
              </div>
            )}
          </Alert>



          <Formik
            innerRef={formRef}

            initialValues={{
              nombre: "",
              email: "",
              telefono: "",
              fuente_postulacion: "Talenty Jobs",
              descripcion: "",
              curriculum: null,

              job_id: params.vacanteId,
              empresa_id: vacanteData.empresa_id,
            }}
            validationSchema={Yup.object({
              nombre: Yup.string().required("El campo nombre es requerido"),
              email: Yup.string().required("El campo email es requerido").email("Proporcione un email válido: example@correo.com"),
              telefono: Yup.string().required("El campo teléfono es requerido"),
              descripcion: Yup.string().required("El campo descripción es requerido"),
            

            })}
            onSubmit={async (values) => {
              
              try {
                const response = await PostulacionStorePublic(values)


                if (response.success) {

                  setProcessing(false);
                  setShowModal(false);
                  setShowAlertHeadless(true);

                } else {
                  setProcessing(false);
                  setShowAlertErrors(true)
                  setErrors("No se pudo guardar la postulación")


                }
              } catch (errors) {
                setProcessing(false);
                setShowAlertErrors(true)
                setErrors(errors)

              }
            }}
            // Agrega el atributo enctype al formulario para enviar archivos
              enctype="multipart/form-data"
          >
            {() => (
              <Form>
                <div className="bg-white pb-5">
                  <div className="text-gray-400">
                    <small className="text-xs">
                      Los campos con <label className="text-red-500">*</label> son
                      requeridos
                    </small>
                  </div>
                  <div className="shadow sm:rounded-md">
                    <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                      <div className="col-span-12">
                        <label
                          htmlFor="Nombre"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Nombre <label className="text-red-500">*</label>
                        </label>
                        <Field
                          name="nombre"
                          type="text"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        <small className="text-xs text-red-500"> <ErrorMessage name="nombre" />  </small>

                      </div>
                      <div className="col-span-12">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email <label className="text-red-500">*</label>
                        </label>
                        <Field
                          name="email"
                          type="text"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        <small className="text-xs text-red-500"> <ErrorMessage name="email" />  </small>
                      </div>
                      <div className="col-span-12">
                        <label
                          htmlFor="telefono"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Teléfono <label className="text-red-500">*</label>
                        </label>
                        <Field
                          name="telefono"
                          type="text"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        <small className="text-xs text-red-500"> <ErrorMessage name="telefono" />  </small>
                      </div>


                      <div className="col-span-12">
                        <label
                          htmlFor="descripcion"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Cuéntanos sobre tí: <label className="text-red-500">*</label>
                        </label>
                        <Field
                          name="descripcion"
                          as="textarea"
                          placeholder="Porque consideras que esta vacante es para ti."
                          className="mt-1 block w-full h-32 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        <small className="text-xs text-red-500"> <ErrorMessage name="descripcion" />  </small>
                      </div>

                      <div className="col-span-12">
                      <CurriculumUpload />
                      </div>

                      

                    </div>
                  </div>

                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>



      <FooterJobs />

      

    </>
  );
}


function CurriculumUpload() {
  const { setFieldValue } = useFormikContext();
  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFieldValue("curriculum", file);
    setUploadedFile(file);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFieldValue("curriculum", file);
    setUploadedFile(file);
  };

  const handleRemoveFile = () => {
    setFieldValue("curriculum", null);
    setUploadedFile(null);
  };

  const handleChooseFile = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <label htmlFor="curriculum" className="block text-sm font-medium text-gray-700">
        Curriculum (opcional)
      </label>
      {!uploadedFile ? (
        <div
          className="mt-1 border-2 border-dashed border-gray-300 rounded-md p-4 cursor-pointer"
          onDrop={handleDrop}
          onDragOver={(event) => {
            event.preventDefault();
          }}
          onClick={handleChooseFile}
        >
          <input
            ref={fileInputRef}
            name="curriculum"
            type="file"
            className="hidden"
            onChange={handleFileChange}
          />
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M20 4h8M20 12h8M4 12h8m-4 4v24a4 4 0 004 4h24a4 4 0 004-4V16a4 4 0 00-4-4h-8m-4 8h8m-4 0v12m-4-12H12v12H6V16zm4 4h8v8h-8v-8z"
              />
            </svg>
            <div className="mt-2">
              <div className="text-sm text-gray-600">
                <label
                  htmlFor="curriculum"
                  className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
                >
                  Haz clic aquí
                </label>{" "}
                o arrastra y suelta un archivo
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center mt-1">
          <svg
            className="mr-2 h-5 w-5 text-gray-400 cursor-pointer"
            fill="currentColor"
            viewBox="0 0 20 20"
            onClick={handleRemoveFile}
          >
            <path
              fillRule="evenodd"
              d="M14.121 5.879a1 1 0 010 1.414L7.414 12l6.707 6.707a1 1 0 11-1.414 1.414L6 13.414l-6.707 6.707a1 1 0 11-1.414-1.414L4.586 12 .879 8.293a1 1 0 111.414-1.414L6 10.586l3.707-3.707a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
          <span className="truncate">{uploadedFile.name}</span>
        </div>
      )}
      {uploadedFile && (
        <small className="text-xs text-red-500">
          <ErrorMessage name="curriculum" />
        </small>
      )}
    </div>
  );
}
