import React from "react"
import { Link } from "react-router-dom"

export default function EmailSent() {
  return (
    <>
      <div className="flex justify-center w-full">
        <div className="p-5 border border-dashed border-gray-400">
          <div className="p-2 flex">
            <label className="w-full mr-2">
              Bienvenido a talenty, para comenzar, te hemos enviado un correo de confirmación.
            </label>
          </div>
          <div className="p-2 flex">
          <Link to="/auth/login">Iniciar Sesión</Link>
          </div>
        </div>
      </div>
    </>
  )
}
