import React, { useContext, useState } from "react"
import EmailSent from "modules/Auth/components/EmailSent"
import Notification from "./../../components/Notifications/Notification"
import { AuthContext } from "contexts/AuthContext"

export default function Register() {
  const [emailSent, setEmailSent] = useState(null)
  const [notification, setNotification] = useState(null)
  const [messageNotification, setMessageNotification] = useState(null)
  const { signUp } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault()

    const data = new FormData(event.currentTarget)
    const response = await signUp({
      name: data.get("name"),
      email: data.get("email"),
      password: data.get("password"),
      perfil: data.get("perfil"),
    })

    if (response.data.success) {
      setMessageNotification("Se creo la cuenta correctamente")
      setEmailSent(true)
    } else {
      setMessageNotification("No se creo la cuenta, reintente")
    }

    setNotification(true)
  }

  const Form = () => {
    return (
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-slate-500 text-sm font-bold">Crear nueva cuenta</h6>
                </div>

                <hr className="mt-6 border-b-1 border-slate-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form onSubmit={handleSubmit}>
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      Nombre
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="nombre"
                      name="name"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="email"
                      name="email"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="password"
                      name="password"
                    />

                    <input type="hidden" name="perfil" defaultValue="administrador" />
                  </div>

                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-1 rounded text-slate-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-slate-600">
                        Estoy de acuerdo con las{" "}
                        <a href="/" className="text-sky-500" onClick={(e) => e.preventDefault()}>
                          Políticas de privacidad
                        </a>
                      </span>
                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-slate-700 text-white active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Crear cuenta
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {notification && (
        <Notification title="Alerta!" message={messageNotification} onClose={() => setNotification(false)} />
      )}
      <div className="flex min-h-full flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-16 w-auto" src={require("assets/img/talenty_logo_200x110.png")} alt="Talenty" />
        </div>
      </div>
      {!emailSent ? <Form /> : <EmailSent />}
    </>
  )
}
