import { Route } from "react-router-dom"
import { Routes, Navigate } from "react-router"

// components

import Navbar from "components/Navbars/AuthNavbar.js"
//import FooterSmall from "components/Footers/FooterSmall.js";

// views

import Login from "../views/auth/Login.js"
import Register from "../views/auth/Register.js"
import React from "react"

export default function Auth() {
  return (
    <>
      <Navbar transparent />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<Navigate to="/auth/login" />} />
          </Routes>
        </section>
      </main>
    </>
  )
}
