/*eslint-disable*/
import React from "react";
import {Link} from "react-router-dom";

import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import Footer from "../components/Footers/Footer.js";


export default function Headhunting() {
    return (
        <>
            <IndexNavbar/>

            <section className="px-12 mt-5 lg:h-screen">
                {/*
                mt-20 sm:h-1/2 sm:w-screen sm:p-10
                */}
                <img
                    className="lg:float-right lg:w-1/2"
                    src={require("assets/img/pattern_react.png")}
                    alt="..."
                />
                {
                    /*
                    md:h-600-px md:ml-8 lg:h-screen
                    * */
                }

                <div className="p-5">
                    <h2 className="font-semibold text-5xl text-slate-600">
                        Construye un equipo asombroso!
                    </h2>

                    <p className="text-xl leading-relaxed text-slate-500 mt-10 mb-10">
                        Enfócate en elegir entre los mejores candidatos para tu empresa, 
                        nosotros nos encargamos de reclutarlos.{" "}
                    </p>
                    <Link
                        to="/auth/register"
                        className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-rose-600 active:bg-indigo-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    >
                        <i className="fas fa-check"></i>
                        Obtener + detalles
                    </Link>

                    <Link
                        to="/auth/login"
                        className="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-indigo-500 active:bg-slate-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    >
                        Agendar llamada
                    </Link>
                </div>


            </section>


            <section className="mt-48 md:mt-40 pb-40 relative bg-slate-100">
                <div
                    className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
                    style={{transform: "translateZ(0)"}}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-slate-100 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
                <div className="container mx-auto">
                    <div className="flex flex-wrap items-center">
                        <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
                            <div
                                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-indigo-600">
                                <img
                                    alt="..."

                                    src="https://images.pexels.com/photos/2265488/pexels-photo-2265488.jpeg?auto=compress&cs=tinysrgb&w=640&h=427&dpr=1"
                                    className="w-full align-middle rounded-t-lg"
                                />
                                <blockquote className="relative p-8 mb-4">
                                    <svg
                                        preserveAspectRatio="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 583 95"
                                        className="absolute left-0 w-full block h-95-px -top-94-px"
                                    >
                                        <polygon
                                            points="-30,95 583,95 583,65"
                                            className="text-indigo-500 fill-current"
                                        ></polygon>
                                    </svg>
                                    <h4 className="text-xl font-bold text-white">
                                        Agiliza tu contratación
                                    </h4>
                                    <p className="text-md font-light mt-2 text-white">
                                       Talenty te ayuda a reclutar y evaluar a los mejores candidatos para cubrir las 
                                       posiciones clave en tu empresa.
                                    </p>
                                </blockquote>
                            </div>
                        </div>

                        <div className="w-full md:w-6/12 px-4">
                            <div className="flex flex-wrap">
                                <div className="w-full md:w-6/12 px-4">
                                    <div className="relative flex flex-col mt-4">
                                        <div className="px-4 py-5 flex-auto">
                                            <div
                                                className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                                <i className="fas fa-th-large"></i>
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">
                                                Enfoque
                                            </h6>
                                            <p className="mb-4 text-slate-500">
                                               Nuestro objetivo en cada proceso es conectarte con candidatos con alta posibilidad de 
                                               ser contratados y permanecer en tu empresa a largo plazo.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="relative flex flex-col min-w-0">
                                        <div className="px-4 py-5 flex-auto">
                                            <div
                                                className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                                <i className="fa fa-share"></i>
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">
                                                Know how
                                            </h6>
                                            <p className="mb-4 text-slate-500">
                                                Nuestro éxito en cada proceso lo logramos gracias a un equipo de headhunters con años de experiencia, metodologías
                                                ágiles de selección, donde nuestros clientes están involucrados  en cada paso. 

                                                Tecnología desarrollada In House, para evaluar soft skills y la gestión ágil de cada vacante, gracias a 
                                                Talenty Assesment Center y Talenty ATS. 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-6/12 px-4">
                                    <div className="relative flex flex-col min-w-0 mt-4">
                                        <div className="px-4 py-5 flex-auto">
                                            <div
                                                className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                                <i className="fa fa-users"></i>
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">Reclutamiento proactivo</h6>
                                            <p className="mb-4 text-slate-500">
                                                Entendemos que cada vacante, cada perfil, cada empresa es diferente, por ello, valoramos conocer 
                                                tus necesidades de contratación, por encima de cualquier lista de requisitos o perfiles de puesto predeterminados.

                     
                                            </p>
                                            <p className="mb-4 text-slate-500">
                                                Nuestro equipo de headhunters realiza sesiones de exploración contigo y los encargados de tomar las decisiones de 
                                                contratación en tu empresa, para dominar las particularidades de cada puesto a cubrir y enviarte únicamente candidatos 
                                                que tengan las habilidades y fit con tu cultura organizacional.

                     
                                            </p>
                                        </div>
                                    </div>
                                    <div className="relative flex flex-col min-w-0">
                                        <div className="px-4 py-5 flex-auto">
                                            <div
                                                className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                                <i className="fas fa-file-alt"></i>
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">
                                                Beneficios de reclutar con Talenty.
                                            </h6>
                                            <p className="mb-4 text-slate-500">
                                                Sáltate los cientos de aplicaciones sin filtrar de linkedin ó el estrés de no 
                                                tener ni una postulación. Tálenty busca, filtra y evalua  los mejores perfiles para que
                                                entrevistes únicamente a quienes vale la pena conocer.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container mx-auto overflow-hidden pb-20">

                    {/* JOB BOARDS SECTION*/}
                    <div className="flex flex-wrap items-center pt-5">

                        <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
                            <img
                                alt="..."
                                className="max-w-full rounded-lg shadow-xl"
                                style={{
                                    transform:
                                        "scale(1) perspective(1040px) rotateY(-11deg) rotateX(3deg) rotate(-1deg)",
                                }}
                                src={require("assets/img/documentation.png")}
                            />
                        </div>

                        <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
                            <div
                                className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                                <i className="fas fa-th-large text-xl"></i>
                            </div>
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                Job Boards
                            </h3>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-600">
                                ¡Recluta como los grandes! Con Talenty puedes crear Job Boards ilimitados, que te
                                permitirán gestionar tus
                                proyectos de selección en tableros Kanban, inspirados en procesos ágiles de la industria
                                Tech.
                            </p>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-600">
                                Puedes añadir candidatos rápidamente, moverlos de una etapa a otra con sólo arrastrar y
                                soltar, enviar feedback individual o masivo,
                                agendar entrevistas y asignarles evaluaciones fácilmente.
                            </p>

                            <Link
                                to="/auth/register"
                                className="font-bold text-slate-700 hover:text-slate-500 ease-linear transition-all duration-150"
                            >
                                Crear mi primer Job Board{" "}
                                <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                            </Link>
                        </div>
                    </div>
                    {/* JOB BOARDS SECTION*/}


                    {/* ASSESMENT CENTER SECTION*/}
                    <div className="flex flex-wrap items-center">
                        <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
                            <div
                                className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                                <i className="fas fa-file-alt text-xl"></i>
                            </div>
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                Assesment center.
                            </h3>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-600">
                                Elimina los sesgos en tu proceso de selección y contrata por habilidades. En nuestro
                                Assesment Center puedes
                                adquirir evaluaciones psicométricas y de competencias, compartirla a tus candidatos con
                                un link único y obtener resultados
                                de inmediato.
                            </p>
                            <div className="block pb-6">
                <span
                    className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Kostik
                </span>
                                <span
                                    className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  IPV
                </span>
                                <span
                                    className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Zavic
                </span>
                                <span
                                    className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Inglés
                </span>
                                <span
                                    className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Wonderlic
                </span>
                                <span
                                    className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Terman
                </span>
                                <span
                                    className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Gordon
                </span>
                                <span
                                    className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  16PF
                </span>
                            </div>
                            <a
                                href="/"
                                target="_blank"
                                className="font-bold text-slate-700 hover:text-slate-500 ease-linear transition-all duration-150"
                            >
                                Ver todas{" "}
                                <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                            </a>
                        </div>

                        <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
                            <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">

                                <img
                                    alt="..."
                                    src={require("assets/img/wonderlic.png")}
                                    className="w-full align-middle rounded-lg absolute shadow-lg -top-160-px left-260-px max-w-210-px"
                                />
                                <img
                                    alt="..."
                                    src={require("assets/img/ipv.png")}
                                    className="w-full align-middle rounded-lg absolute shadow-lg max-w-180-px -top-225-px left-40-px z-2"
                                />
                                <img
                                    alt="..."
                                    src={require("assets/img/candidatos.png")}
                                    className="w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-50-px top-25-px"
                                />

                                <img
                                    alt="..."
                                    src={require("assets/img/results.png")}
                                    className="w-full align-middle rounded absolute shadow-xl max-w-120-px left-195-px top-95-px"
                                />
                            </div>
                        </div>
                    </div>
                    {/* ASSESMENT CENTER SECTION*/}


                    {/* POOL CANDIDATOS SECTION*/}
                    <div className="flex flex-wrap items-center pt-20 pb-10">
                        <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
                            <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">

                                <img
                                    alt="..."
                                    src={require("assets/img/candidatos2.png")}
                                    className="w-full align-middle rounded-lg absolute shadow-lg -top-160-px left-260-px max-w-210-px"
                                />
                                <img
                                    alt="..."
                                    src={require("assets/img/candidatos1.png")}
                                    className="w-full align-middle rounded-lg absolute shadow-lg max-w-180-px -top-225-px left-40-px z-2"
                                />
                                <img
                                    alt="..."
                                    src={require("assets/img/candidatos3.png")}
                                    className="w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-50-px top-25-px"
                                />


                            </div>
                        </div>

                        <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
                            <div
                                className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                                <i className="fas fa-users text-xl"></i>
                            </div>
                            <h2 className="font-semibold text-4xl">Pool de candidatos</h2>
                            <h4 className="text-slate-500">¿Aun sigues batallando por
                                encontrar el CV de ese candidat@ que te pareció genial? </h4>
                            <p className="text-lg leading-relaxed mt-4 mb-4 text-slate-500">
                                Manten tu pool de candidatos actualizado. Talenty te permite gestionar tu cartera de
                                candidatos,
                                tener su perfil y documentos siempre a la mano, y filtrarlos fácilmente. Puedes
                                añadirlos a un proceso de selección ó
                                enviarle evaluaciones cuando quieras.
                            </p>

                            <Link
                                to="/auth/register"
                                className="font-bold text-slate-700 hover:text-slate-500 ease-linear transition-all duration-150"
                            >
                                Comenzar...{" "}
                                <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                            </Link>
                        </div>
                    </div>
                    {/* POOL CANDIDATOS SECTION*/}

                </div>


                {/* GOOGLE FOR JOBS SECTION*/}
                <div className="container mx-auto px-4 pb-5 pt-20">
                    <div className="items-center flex flex-wrap">
                        <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
                            <div className="md:pr-12">
                                <div
                                    className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                                    <i className="fas fa-share text-xl"></i>
                                </div>
                                <h3 className="text-3xl font-semibold">
                                    Google for Jobs
                                </h3>
                                <h4 className="text-slate-500">
                                    Pon en linea tu vacante con Talenty.
                                </h4>

                                <p className="mt-4 text-lg leading-relaxed text-slate-500">
                                    Todas tus vacantes pueden publicarse en Google for Jobs de
                                    manera gratuita, siguiendo las mejores prácticas recomendadas para dar mayor
                                    visibilidad y probabilidad de
                                    recibir candidatos.
                                </p>
                                <p className="mt-4 text-lg leading-relaxed text-slate-500">
                                    De igual forma, puedes compartir tu vacante en linkedin y redes sociales para
                                    comenzar a recibir
                                    candidatos en tus Job Boards.
                                </p>

                                <Link
                                    to="/auth/register"
                                    className="mt-6 font-bold text-slate-700 hover:text-slate-500 ease-linear transition-all duration-150"
                                >
                                    Comenzar...{" "}
                                    <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                                </Link>

                                {/*  <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-slate-50 mr-3">
                          <i className="fas fa-fingerprint"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-slate-500">
                          Built by Developers for Developers
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-slate-50 mr-3">
                          <i className="fab fa-html5"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-slate-500">
                          Carefully crafted code for Components
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-slate-50 mr-3">
                          <i className="far fa-paper-plane"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-slate-500">
                          Dynamic Javascript Components
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul> */}

                            </div>
                        </div>

                        <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
                            <img
                                alt="..."
                                className="max-w-full rounded-lg shadow-xl"
                                style={{
                                    transform:
                                        "scale(1) perspective(1020px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                                }}
                                src={require("assets/img/googlejobs.png")}
                            />
                        </div>
                    </div>
                </div>
                {/* GOOGLE FOR JOBS SECTION*/}


            </section>
            <section className="py-2 bg-slate-600 overflow-hidden">
                <div className="container mx-auto pb-64">
                    <div className="flex flex-wrap justify-center">
                        <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
                            <div
                                className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                                <i className="fas fa-users text-xl"></i>
                            </div>
                            <h3 className="text-3xl mb-2 font-semibold leading-normal text-white">
                                ¿Aun tienes dudas si somos tu mejor opción? Habla con nosotros.
                            </h3>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-400">
                                Agendemos {" "}
                                <Link
                                    to="/auth/register"
                                    className="text-slate-300"

                                >
                                    una llamada breve
                                </Link>{" "}
                                con uno de nuestros especialistas para resolver tus dudas y brindarte la asesoría que necesitas.
                            </p>
                            
                            <Link
                                to="/auth/register"
                                className="github-star mt-4 inline-block text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-slate-700 active:bg-slate-600 uppercase text-sm shadow hover:shadow-lg"
                            >
                                Agendar llamada de 20 min.
                            </Link>
                        </div>

                        <div className="w-full md:w-4/12 px-4 mr-auto ml-auto mt-64 relative">
                            <i className="fa fa-users text-slate-700 absolute -top-150-px -right-100 left-auto opacity-80 text-55"></i>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-16 bg-slate-200 relative pt-10">
                <div
                    className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
                    style={{transform: "translateZ(0)"}}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-slate-200 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>

                <div className="container mx-auto">
                    <div
                        className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
                        <div className="w-full text-center lg:w-8/12">
                            {/* <p className="text-4xl text-center">
                <span role="img" aria-label="love">
                  😍
                </span>
              </p> */}
                            <h3 className="font-semibold text-3xl">
                                Recluta con Talenty.
                            </h3>
                            <p className="text-slate-500 text-lg leading-relaxed mt-4 mb-4">
                                Registrate hoy mismo y accede a todas las funcionalidades de Talenty ATS. Si aun tienes
                                dudas, ponte en
                                contacto con nosotros.
                            </p>
                            <div className="sm:block flex flex-col mt-10">
                                <Link
                                    to="/auth/register"
                                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-sky-500 active:bg-sky-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                >
                                    <i className="fa fa-user text-m mr-1"></i>
                                    Registrarme
                                </Link>
                                <Link
                                    to="/auth/register"
                                    className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-slate-700 active:bg-slate-600 uppercase text-sm shadow hover:shadow-lg"
                                >
                                    <i className="fa fa-paper-plane text-m mr-1"></i>
                                    <span>Contactarlos</span>
                                </Link>
                            </div>
                            <div className="text-center mt-16"></div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}
