import axios from "axios"

const createAxiosInstance = () => {
  const sesion = JSON.parse(localStorage.getItem("talenty-user"))
  const API_KEY = `Bearer ${sesion?.token}`

  const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API,
  })

  instance.interceptors.request.use(
    (config) => {
      config.headers = { ...config.headers, Authorization: API_KEY }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  return instance
}

export const apitalenty = createAxiosInstance
