import { useEffect, useState } from "react";
import React from "react";

function Alert({ type, show, children, onClose }) {
  const [render, setRender] = useState(show);
  const types = {
    success: { color: "bg-green-500", icon: "fas fa-circle-check" },
    info: { color: "bg-sky-500", icon: "fas fa-info-circle" },
    danger: { color: "bg-red-500", icon: "fas fa-circle-xmark" },
    warning: { color: "bg-yellow-500", icon: "fas fa-warning" },
  };

  useEffect(() => {
    setRender(show);
  }, [show]);

  return (
    <>
      {render ? (
        <div
          className={`flex px-6 py-4 border-0 rounded relative mb-4 ${types[type].color}`}
        >
          <span className="text-xl inline-block mr-5 align-middle">
            <i className={types[type].icon} />
          </span>
          <span className="inline-block align-middle mr-8">{children}</span>
          <button
            className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            onClick={() => onClose(false)}
          >
            <span>×</span>
          </button>
        </div>
      ) : null}
    </>
  );
}
export default Alert;
