import React from "react";
import JobsNavbar from "components/Navbars/JobsNavbar";

export default function HeaderJobs({ title, description }) {
  return (
    <>

    <JobsNavbar/>

<div className="relative mx-auto mt-14 items-center justify-center isolate overflow-hidden bg-gray-900 py-12 sm:py-12">
  {/* Imagen para pantallas pequeñas */}
  <img
    alt="talenty-sm"
    src={require("assets/img/bgblue-sm.jpeg")}
    className="lg:hidden absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
  />
  {/* Imagen para pantallas grandes */}
  <img
    alt="talenty-lg"
    src={require("assets/img/bgblue-lg.png")}
    className="hidden lg:block absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
  />
  <div className="mx-auto items-center justify-center px-6 lg:px-8 text-center flex">
    <div className="mx-auto items-center justify-center lg:mx-0">
      <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">{title}</h2>
      <p className="mt-6 text-lg leading-8 text-white">{description}</p>
    </div>
  </div>
</div>
</>
  );
}
