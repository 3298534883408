import { apitalenty } from "hooks/useAPIaxios"

export default async function ListVacantesByUser() {
  const apiInstance = apitalenty();

  try {
    const response = await apiInstance.get("jobs")

    if (response.status !== 200) {
      return Promise.reject("No se pudo obtener la lista de vacantes")
    }

    return response.data
  } catch ({ response }) {
    /**
     * Errors catched
     * 500 enpoint no exist or another error uncaught
     * 404 The user has not data
     */
    throw response.data.message
  }
}
