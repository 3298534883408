import React, { useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
//import { useAuth } from "../../hooks/useAuth"

import Loader from "components/Loaders/Loader";

import { AuthContext } from "contexts/AuthContext";

import { useConsoleLog } from "../../hooks/useConsoleLog";

export default function Login() {
  const { signIn, isAuthenticated } = useContext(AuthContext)
  const { log } = useConsoleLog();

  log.info(isAuthenticated)
  

  
  const [error, setError] = useState(null)
  const redirect = useNavigate()
  //const { signIn } = useAuth()
  const [processing, setProcessing] = useState(false);
  

  const handleSubmit = async (event) => {
    try {
      event.preventDefault()
      setError(null)
      setProcessing(true);

      const data = new FormData(event.currentTarget)
      await signIn({ email: data.get("email"), password: data.get("password") })

      log.info("isAuthenticated", isAuthenticated)

      const session = JSON.parse(localStorage.getItem("talenty-user"))

      if (session) {
        redirect("/admin/jobboards", { replace: true })
      }
    } catch (error) {
      setError(error.errors.error)
      setProcessing(false);
    }
  }

  return (
    <>
      <div className="flex min-h-full flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-auto w-auto" src={require("assets/img/talenty_logo_200x110.png")} alt="Talenty" />
        </div>
      </div>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div
              className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg
                            bg-white border-0"
            >
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h4 className="text-slate-500 text-sm font-bold">Iniciar sesión</h4>
                </div>
                <hr className="mt-6 border-b-1 border-slate-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form onSubmit={handleSubmit}>
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600
                                               bg-white rounded text-sm shadow focus:outline-none focus:ring
                                               w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      name="email"
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      Contraseña
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600
                                               bg-white rounded text-sm shadow focus:outline-none focus:ring w-full
                                               ease-linear transition-all duration-150"
                      placeholder="Password"
                      name="password"
                    />
                  </div>
                  <div className="relative w-full mb-3">{error && <p className="text-red-500 mb-2">{error}</p>}</div>

                  {/*
                                    <div>
                                        <label className="inline-flex items-center cursor-pointer">
                                            <input
                                                id="customCheckLogin"
                                                type="checkbox"
                                                className="form-checkbox border-0 rounded text-slate-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            />
                                            <span className="ml-2 text-sm font-semibold text-slate-600">Recordarme</span>
                                        </label>
                                    </div>
                                    */}
                  <div className="text-center mt-6">
                    <button
                      className="bg-indigo-600 text-white active:bg-indigo-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                       {!processing ?  "Entrar" :   <Loader /> }
                      
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              {/**
               * @todo agregar funcionalidad recuperar contraseña
               */}
              {/*<div className="w-1/2">
                                <a href="/"
                                   onClick={(e) => e.preventDefault()}
                                   className="text-indigo-600"
                                >
                                    <small>Forgot password?</small>
                                </a>
                            </div>*/}
              <div className="text-right">
                <Link to="/auth/register" className="text-rose-600">
                  <small>Crear nueva cuenta</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
