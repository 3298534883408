import { useNavigate } from "react-router-dom";
import { apitalenty } from "./useAPIaxios";
import { useLocalStorage } from "./useLocalStorage";
import { useState } from "react";

export const useAuth = () => {
  const redirect = useNavigate();
  const apiInstance = apitalenty();
  const [storedToken, setStoredToken] = useLocalStorage('talenty-token', null);
  const [storedUser, setStoredUser] = useLocalStorage('talenty-user', null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  const signIn = async ({ email, password }) => {
    try {
      const response = await apiInstance.post(
        process.env.REACT_APP_BACKEND_API + "/login",
        { email, password },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        }
      )

      const talentyUser = response.data

      if (talentyUser.success) {
        setIsAuthenticated(true);
        setUser(response.data.data);
        setStoredToken(response.data.data.token);
        setStoredUser(response.data.data);
        redirect("/admin/jobboards", { replace: true })
        cleanLocalStorage(); // remove old values once
      }

      return response?.data
    } catch (error) {
      throw error?.response?.data
    }
  }

  const signUp = async (data) => {
    try {
      const response = await apiInstance.post(process.env.REACT_APP_BACKEND_API + "/register", data, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      return response;
    } catch (err) {
      console.log("Hubo un problema al registrar la cuenta", err)
      return { data: { success: false } }
    }
  }

  const signOut = async () => {
    try {
      if (storedToken) {
        const response = await apiInstance.post("logout")

        const logoutResponse = response.data

        if (logoutResponse.success) {
          setIsAuthenticated(false);
          setUser(null);
          setStoredToken(null);
          setStoredUser(null);
          redirect("/auth/login", { replace: true })
          cleanLocalStorage(); // remove old values once
        } else {
          console.error('Logout error:', response);
        }
      } else {
        console.warn('No token found for logout');
      }

    } catch (error) {
      console.error('Logout error:', error);
    }
  }

  const cleanLocalStorage = () => {
    const oldUser = 'user' in localStorage;
    const oldToken = 'token' in localStorage;

    if (oldUser || oldToken) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      window.location.reload();
    }
  }

  return { signIn, signUp, signOut, isAuthenticated, user, setIsAuthenticated, setUser, storedUser, storedToken }
}
