import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route } from "react-router-dom";
import { Routes } from "react-router";
import loadable from '@loadable/component' //use for lazy load components https://loadable-components.com/docs/loadable-vs-react-lazy/


import "@fortawesome/fontawesome-free/css/all.min.css";
import "./index.css";

//components
import { AuthProvider } from "./contexts/AuthContext";


//layouts
import Auth from "./layouts/Auth.js";
//const Auth = loadable(() => import('./layouts/Auth.js'));
//import Admin from "./layouts/Admin.js";

const Admin = loadable(() => import('./layouts/Admin.js'));

// views without layouts
import Index from "./views/Index.js";
import Headhunting from "./views/Headhunting";
//const Index = loadable(() => import('./views/Index.js'));
//import RequireAuth from "./components/RequireAuth";
//const RequireAuth = loadable(() => import('./components/RequireAuth'));
//import Dashboard from "./views/admin/Dashboard";
const Dashboard = loadable(() => import('./views/admin/Dashboard'));
//import JobBoards from "./views/admin/JobBoards";
const JobBoards = loadable(() => import('./views/admin/JobBoards'));
//import Board from "./views/admin/Board";
const Board = loadable(() => import('./views/admin/Board'));
//import BoardOriginal from "./views/admin/BoardOriginal";
//import Maps from "./views/admin/Maps";
const Maps = loadable(() => import('./views/admin/Maps'));
//import Settings from "./views/admin/Settings";
const Settings = loadable(() => import('./views/admin/Settings'));
//import Tables from "./views/admin/Tables";
const Tables = loadable(() => import('./views/admin/Tables'));
//import Empresas from "./views/admin/Empresas";
const Empresas = loadable(() => import('./views/admin/Empresas'));
//import Candidatos from "views/admin/Candidatos";
const Candidatos = loadable(() => import('views/admin/Candidatos'));
//import Evaluaciones from "views/admin/Evaluaciones";
const Evaluaciones = loadable(() => import('views/admin/Evaluaciones'));

const Reclutadores = loadable(() => import('views/admin/Reclutadores'));

const AddCredits = loadable(() => import('modules/evaluaciones/views/AddCredits'));

//views for public Jobs
import Jobs from "views/jobs/Jobs";
//const Jobs = loadable(() => import('views/jobs/Jobs'));
import ViewJob from "views/jobs/ViewJob";
//const ViewJob = loadable(() => import('views/jobs/ViewJob'));

const Confirmation = loadable(() => import('./views/auth/Confirmation'))

import { AuthContext } from "./contexts/AuthContext";
import { Navigate } from "react-router";
import { useContext } from "react";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated ? children : <Navigate to="/auth/login" replace />;
};

const PublicRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  return !isAuthenticated ? children : <Navigate to="/admin/jobboards" replace />; // Redirect to dashboard if authenticated
};



const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          { /* page landing*/}
          <Route path="/" element={<PublicRoute><Index /></PublicRoute>} />
          <Route path="/headhunting" element={<PublicRoute><Headhunting /></PublicRoute>} />
          { /* layout auth */}
          <Route path="/auth/*" element={<PublicRoute><Auth /></PublicRoute>} />+
          { /* layout admin */}
          <Route path="/admin/*" element={<PrivateRoute><Admin /></PrivateRoute>}>
            {/*<Route path="/admin/*" element={<Admin />}>*/}
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="candidatos" element={<Candidatos />} />
            <Route path="jobboards" element={<JobBoards />} />
            <Route path="board/:vacanteId" element={<Board />} />
            <Route path="maps" element={<Maps />} />
            <Route path="settings" element={<Settings />} />
            <Route path="tables" element={<Tables />} />
            <Route path="empresas" element={<Empresas />} />
            <Route path="reclutadores" element={<Reclutadores />} />
            <Route path="evaluaciones" element={<Evaluaciones />} />
            <Route path="evaluaciones/creditos" element={<AddCredits />} />
          </Route>
          { /* page email*/}
          <Route path="email">
            <Route path="verify/:id/:hash" element={<Confirmation />} />
          </Route>
          { /* layout empleos */}
          <Route path="/empleos/*" element={<Jobs />} />
          { /* page empleo */}
          <Route path="empleo/:title/:vacanteId" element={<ViewJob />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
)
