import React, { useState, useEffect } from 'react';

const Notification = ({ title, message, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    visible && (
      <div className="fixed z-50 top-0 right-0 m-4 bg-white border-gray-200 border p-4 rounded-lg shadow-lg flex">
        <div className="mr-2 bg-white text-green-500 rounded-full p-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <div className='mr-2'>
          <p className="text-base font-semibold leading-6 text-gray-900">{title}</p>
          <p className="text-sm text-gray-500 mt-2">{message}</p>
        </div>
        <button
          className="ml-auto absolute top-2 right-2 cursor-pointer text-xs text-gray-400 hover:text-red-400"
          onClick={() => {
            setVisible(false);
            onClose();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    )
  );
};

export default Notification;
